import { createContext, FC, useContext, useEffect, useState } from "react";
import { fetchTermsAndConditionsVersion } from "../../services/contentful/termsandconditions";
import {
  subscribeToMemberAndConsent,
  subscribeToVersionAndConsentTime,
} from "../../services/members";
import { AuthContext } from "./AuthProvider";
import { ShoppingAllocation } from "../../models";
import { DefaultShoppingAllocation } from "../../constants";
import { track } from "../../services/events";
import { GOALS } from "../../components-2/goals/config";
import { DIETARY_PREFERENCES } from "../../components-2/dietary-preference/config";

interface Props {}

type MemberContextState = {
  memberId: string;
  visitCount: number | null;
  consented: boolean | null;
  justUnconsented: boolean;
  marketingConsented: boolean | null;
  termsAndConditionsUpdated: boolean;
  shoppingAllocation: ShoppingAllocation;
  feedbackSubmittedOrDismissed: number | null;
  isNewUser: boolean;
  email: string | null;
  hasNameBeenEnteredBefore: boolean;
  name: string | null;
  hasEmailBeenEnteredBefore: boolean;
  hasClickedPointsPromo: boolean;
  latestConsentTime: number | null;
  oldestConsentTime: number | null;
  thirtySecondsOnSite: boolean;
  showNutrientTracker: boolean;
  eligibleForFruitAndVegMayCampaign: boolean;
  joinedFruitAndVegMayCampaignLeaderboard: boolean;
  fruitAndVegMayCampaignJoinDate: string | null;
  goals: GOALS[] | null;
  dietaryPreferences: DIETARY_PREFERENCES[] | null;
  communicationWrapUp: boolean | undefined;
  communicationInsights: boolean | undefined;
  communicationExpertAdvice: boolean | undefined;
  privateFirstTimeConsentingToTracker: boolean;
} | null;

export const MemberContext = createContext<MemberContextState>(null);

const MemberProvider: FC<Props> = ({ children }) => {
  const { user } = useContext(AuthContext);
  const memberId = user?.uid;
  const [consented, setConsented] = useState<boolean | null>(null);
  const [justUnconsented, setJustUnconsented] = useState<boolean>(false);
  const [
    privateFirstTimeConsentingToTracker,
    setPrivateFirstTimeConsentingToTracker,
  ] = useState<boolean>(false);
  const [marketingConsented, setMarketingConsented] = useState<boolean | null>(
    null
  );
  const [agreedTermsVersion, setAgreedTermsVersion] = useState<number | null>(
    null
  );
  const [latestTermsVersion, setLatestTermsVersion] = useState<number | null>(
    null
  );
  const [latestConsentTime, setLatestConsentTime] = useState<number | null>(
    null
  );
  const [oldestConsentTime, setOldestConsentTime] = useState<number | null>(
    null
  );
  const [thirtySecondsOnSite, setThirtySecondsOnSite] =
    useState<boolean>(false);
  const [goals, setGoals] = useState<GOALS[] | null>(null);
  const [dietaryPreferences, setDietaryPreferences] = useState<
    DIETARY_PREFERENCES[] | null
  >(null);

  const [shoppingAllocation, setShoppingAllocation] =
    useState<ShoppingAllocation>(DefaultShoppingAllocation);

  const [feedbackSubmittedOrDismissed, setFeedbackSubmittedOrDismissed] =
    useState<number | null>(null);

  const [name, setName] = useState<string | null>(null);
  const [hasNameBeenEnteredBefore, setHasNameBeenEnteredBefore] =
    useState<boolean>(false);
  const [email, setEmail] = useState<string | null>(null);
  const [hasEmailBeenEnteredBefore, setHasEmailBeenEnteredBefore] =
    useState<boolean>(false);
  const [visitCount, setVisitCount] = useState<number | null>(null);
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const [showNutrientTracker, setShowNutrientTracker] =
    useState<boolean>(false);
  const [hasClickedPointsPromo, setHasClickedPointsPromo] =
    useState<boolean>(false);
  const [
    eligibleForFruitAndVegMayCampaign,
    setEligibleForFruitAndVegMayCampaign,
  ] = useState<boolean>(false);
  const [
    joinedFruitAndVegMayCampaignLeaderboard,
    setJoinedFruitAndVegMayCampaignLeaderboard,
  ] = useState<boolean>(false);
  const [fruitAndVegMayCampaignJoinDate, setFruitAndVegMayCampaignJoinDate] =
    useState<string | null>(null);

  const [communicationWrapUp, setCommunicationWrapUp] = useState<
    boolean | undefined
  >(undefined);
  const [communicationInsights, setCommunicationInsights] = useState<
    boolean | undefined
  >(undefined);
  const [communicationExpertAdvice, setCommunicationExpertAdvice] = useState<
    boolean | undefined
  >(undefined);

  useEffect(() => {
    const init = async () => {
      setLatestTermsVersion(await fetchTermsAndConditionsVersion());
    };

    init();

    const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
      setThirtySecondsOnSite(true);
    }, 30000);

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (!memberId) return;

    const unsubscribe1 = subscribeToVersionAndConsentTime(
      memberId,
      (latestConsentTime, oldestConsentTime, consentVersion, newUser) => {
        setAgreedTermsVersion(consentVersion);
        setLatestConsentTime(latestConsentTime);
        setOldestConsentTime(oldestConsentTime);
        if (newUser) {
          setIsNewUser(newUser);
        }
      }
    );

    const unsubscribe2 = subscribeToMemberAndConsent(memberId, (member) => {
      if (consented && !member.dataUsageConsented) {
        setJustUnconsented(true);
      }
      if (member?.feedbackSubmittedOrDismissed) {
        setFeedbackSubmittedOrDismissed(member?.feedbackSubmittedOrDismissed);
      }
      setPrivateFirstTimeConsentingToTracker(
        member?.privateFirstTimeConsentingToTracker ?? false
      );
      setGoals(member?.goals ?? null);
      setDietaryPreferences(member?.dietaryPreferences ?? null);
      setName(member?.name ?? null);
      setHasNameBeenEnteredBefore(member?.hasNameBeenEnteredBefore);
      setEmail(member?.email ?? null);
      setHasEmailBeenEnteredBefore(member?.hasEmailBeenEnteredBefore);
      setHasClickedPointsPromo(member?.hasClickedPointsPromo);
      track(memberId, "Is Member Consented", {
        consented: member?.dataUsageConsented,
      });
      setConsented(member?.dataUsageConsented);
      setMarketingConsented(member?.marketingConsented);
      setShoppingAllocation(
        member?.shoppingAllocation ?? DefaultShoppingAllocation
      );
      setVisitCount(member?.visitCount ?? 0);
      setShowNutrientTracker(member?.showNutrientTracker);
      setEligibleForFruitAndVegMayCampaign(
        member?.eligibleForFruitAndVegMayCampaign
      );
      setJoinedFruitAndVegMayCampaignLeaderboard(
        member?.joinedFruitAndVegMayCampaignLeaderboard
      );
      setFruitAndVegMayCampaignJoinDate(member?.fruitAndVegMayCampaignJoinDate);
      setCommunicationWrapUp(member?.communicationWrapUp);
      setCommunicationInsights(member?.communicationInsights);
      setCommunicationExpertAdvice(member?.communicationExpertAdvice);
    });

    return () => {
      unsubscribe1();
      unsubscribe2();
    };
  }, [consented, memberId]);

  const termsAndConditionsUpdated =
    agreedTermsVersion !== null &&
    latestTermsVersion !== null &&
    agreedTermsVersion < latestTermsVersion;

  const member = memberId
    ? {
        memberId,
        visitCount,
        consented,
        justUnconsented,
        marketingConsented,
        termsAndConditionsUpdated,
        shoppingAllocation,
        feedbackSubmittedOrDismissed,
        isNewUser,
        name,
        hasNameBeenEnteredBefore,
        email,
        hasEmailBeenEnteredBefore,
        latestConsentTime,
        oldestConsentTime,
        thirtySecondsOnSite,
        showNutrientTracker,
        hasClickedPointsPromo,
        goals,
        dietaryPreferences,
        eligibleForFruitAndVegMayCampaign,
        joinedFruitAndVegMayCampaignLeaderboard,
        fruitAndVegMayCampaignJoinDate,
        communicationWrapUp,
        communicationInsights,
        communicationExpertAdvice,
        privateFirstTimeConsentingToTracker,
      }
    : null;

  return (
    <MemberContext.Provider value={member}>{children}</MemberContext.Provider>
  );
};

export default MemberProvider;
