import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import MainVsSometimesSection from "../components-2/main-vs-sometimes/MainVsSometimesSection";
import TimeFrameTabs from "../components/TimeFrameTabs";
import FoodGroupInsightsSection from "../components/serves-remaining/FoodGroupInsightsSection";
import WaysToWellSection from "../components/WaysToWellSection";
import CenteredLoadingIndicator from "../components/CenteredLoadingIndicator";
import {
  fetchMainPageContent,
  MainPageContent,
} from "../services/contentful/main";
import UpdatedTermsDialog from "../components/consent/UpdatedTermsDialog";
import { isNonProdEnv } from "../utils/debug";
import useMember from "../hooks/useMember";
import { COLOR_GREYSCALE, DARK_GREEN, GREYSCALE, WHITE } from "../theme";
import ConsentDialog from "../components/consent/ConsentDialog";
import {
  HttpStatusCodes,
  NOT_FOUND_REDIRECT_URL,
  POINTS_PROMO_END_DATE,
  UNAUTHORIZED_REDIRECT_URL,
} from "../constants";

import ErrorNotice from "../components/ErrorNotice";
import { useTheme } from "@material-ui/core/styles";
import BalanceOfShopV2 from "../components/balance-of-shop-v2/BalanceOfShopV2";
import { NutrientTrackerSummary } from "../components-2/nutrient-tracker";
import useHidePrograms from "../hooks/useHidePrograms";
import HealthierOptions from "../components-2/healthier-options";
import { PageLayout } from "../components-2/page-layout";
import { FeatureCarouselWithHeader } from "../components-2/feature-carousel";
import HealthyInspiration from "../components/healthy-inspiration/HealthyInspiration";
import BreakSeparator from "../components/BreakSeparator";
import { shouldShowFruitsAndVegChallenge } from "../utils/misc";

import SwiperCore, { A11y, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import GoalsSummary from "../components-2/goals/GoalsSummary";
import useEvents from "../hooks/useEvents";
import { HOMEPAGE_EVENT } from "../events";
import HealthyInsights from "../components-2/healthy-insights/HealthyInsights";
import { setMemberEligibleForSwapsCampaign } from "../services/members";
import useFeatureFlags from "../hooks/useFeatureFlags";
import FruitAndVegChallenge from "../components-2/fruit-and-veg-challenge/";
import { formatGMTToSydneyISO, getSydneyISOTime } from "../utils/dates";
import { addTwoWeeks } from "../components-2/fruit-and-veg-challenge/FruitAndVegChallengeJoined";

SwiperCore.use([Navigation, Pagination, A11y]);

const useStyles = makeStyles<Theme & { memberConsented: boolean }>(
  ({ breakpoints, spacing, memberConsented }) => ({
    logo: {
      height: 54,
      marginTop: spacing(4),
    },
    subtitle: {
      marginTop: spacing(3),
    },
    title: {
      fontSize: 36,
      color: GREYSCALE,
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column-reverse",
      [breakpoints.up("md")]: {
        flexDirection: "column",
      },
    },
    wrapper: {
      ...(memberConsented && { opacity: 0.15 }),
      position: "relative",
    },
    personalizationButton: {
      height: 48,
      borderRadius: "12px",
      backgroundColor: "#B9E04C",
      boxShadow: "none",
      marginBottom: "16px",
      padding: "12px 24px",
      textTransform: "initial",
      color: "#0A2822",
      [breakpoints.down("md")]: {
        marginTop: spacing(2),
        padding: "12px 16px",
      },
      "&:hover": {
        backgroundColor: "#A0C734",
        boxShadow: "none",
      },
    },
    personlizationButtonAndTimeTab: {
      [breakpoints.up("md")]: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
    },
    timeFrame: {
      margin: "24px 0",
      width: "100%",
      order: 1,
      [breakpoints.up("md")]: {
        width: "auto",
        order: 2,
      },
    },
    timeframeStickyContainer: {
      display: "none",
      background: WHITE,
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      width: "100%",
      margin: 0,
      zIndex: 99,
      borderBottom: `solid 1px ${COLOR_GREYSCALE[200]}}`,
    },
    timeframeSticky: {
      width: "100%",
      display: "block",
      marginTop: "4px",
      [breakpoints.up("md")]: {
        display: "none",
      },
    },
    timeframeStickyVisible: {
      display: "block",
    },
    foodtrackerHeaderText: {
      display: "none",
      [breakpoints.up("lg")]: {
        margin: "0px",
        display: "block",
        fontSize: "40px",
        lineHeight: "48px",
      },
    },
    sectionHeaderText: {
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      margin: "0px",
      fontSize: "22px",
      lineHeight: "30px",
      color: COLOR_GREYSCALE[900],
      [breakpoints.down(375)]: {
        paddingLeft: 8,
      },
      [breakpoints.up("md")]: {
        fontSize: "36px",
        fontFamily: "Gilroy-Bold",
        fontWeight: "normal",
        paddingBottom: "8px",
        borderBottom: "3px solid black",
      },
    },
    swiperContainer: {
      height: "fit-content",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
      background: "#fff",
      borderRadius: "12px",
      paddingBottom: "48px",
      "--swiper-pagination-color": DARK_GREEN,
      "--swiper-pagination-bullet-inactive-color": COLOR_GREYSCALE[100],
      "--swiper-pagination-bullet-inactive-opacity": "1",
      "--swiper-pagination-bullet-size": "9px",
      "--swiper-pagination-bullet-horizontal-gap": "8px",
    },
  })
);

interface Props {
  error?: HttpStatusCodes;
}

const MainPage = ({ error }: Props) => {
  const { track } = useEvents();
  const [content, setContent] = useState<MainPageContent>();
  const { member } = useMember();
  const classes = useStyles(!!member?.consented);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const timeframeRef = useRef(null);
  const hidePrograms = useHidePrograms();
  const { featureFlags } = useFeatureFlags();
  const showFruitAndVegChallenge = useMemo(() => {
    // this is to still show the banner for those who completed the challenge for another 2 weeks
    const currDate = getSydneyISOTime();
    const promoEndDate = formatGMTToSydneyISO(POINTS_PROMO_END_DATE!);
    const promoEndDatePlus2Weeks = formatGMTToSydneyISO(
      addTwoWeeks(POINTS_PROMO_END_DATE!)
    );
    const completedChallenge =
      member?.eligibleForFruitAndVegMayCampaign &&
      currDate >= promoEndDate &&
      currDate <= promoEndDatePlus2Weeks;

    return (
      shouldShowFruitsAndVegChallenge() ||
      completedChallenge ||
      featureFlags?.fruitAndVegChallenge?.enable?.specificMembers?.includes(
        member?.memberId as string
      )
    );
  }, [
    member?.eligibleForFruitAndVegMayCampaign,
    featureFlags?.fruitAndVegChallenge?.enable?.specificMembers,
    member?.memberId,
  ]);

  useEffect(() => {
    const init = async () => {
      setContent(await fetchMainPageContent());
      if (member?.memberId) {
        await setMemberEligibleForSwapsCampaign(member?.memberId);
      }
      const intercomElement = document.querySelector(
        ".intercom-lightweight-app"
      );
      if (intercomElement) {
        //removes from hl-web page
        intercomElement.remove();
      }
    };
    track(HOMEPAGE_EVENT.LOADED);
    init();
    // eslint-disable-next-line
  }, []);

  if (error) {
    if (error === HttpStatusCodes.Unauthorized) {
      window.location.href = UNAUTHORIZED_REDIRECT_URL;
      return null;
    } else if (error === HttpStatusCodes.NotFound) {
      window.location.href = NOT_FOUND_REDIRECT_URL;
      return null;
    }

    return <ErrorNotice error={error} />;
  }

  if (!content) {
    return <CenteredLoadingIndicator loading />;
  }

  return (
    <PageLayout isHomePage usersName={member?.name}>
      <FeatureCarouselWithHeader />
      {isMobile && showFruitAndVegChallenge && (
        <BreakSeparator marginBottom={8} />
      )}
      {showFruitAndVegChallenge && <FruitAndVegChallenge />}
      <Grid container className={classes.header}>
        <Grid
          container
          spacing={showFruitAndVegChallenge ? 3 : 0}
          alignItems="center"
          justifyContent="space-between"
          style={{ display: "flex" }}
        >
          {!isMobile && (
            <Grid item>
              <Typography
                variant="h1"
                component="h1"
                className={classes.foodtrackerHeaderText}
              >
                Food Tracker dashboard
              </Typography>
            </Grid>
          )}
          <Grid
            item
            className={classes.timeFrame}
            style={{
              ...(showFruitAndVegChallenge ? { paddingBottom: 0 } : {}),
            }}
            ref={timeframeRef}
          >
            <TimeFrameTabs
              isHomePage
              reverseOrder={true}
              textColorMobile={COLOR_GREYSCALE[800]}
              textColorDesktop={COLOR_GREYSCALE[800]}
            />
          </Grid>
        </Grid>

        {!isMobile && (
          <Grid xs={12}>
            <BreakSeparator marginBottom={40} marginTop={40} />
          </Grid>
        )}
      </Grid>

      {/*Mobile*/}
      {isMobile && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid item>
              <MainVsSometimesSection />
            </Grid>
            <Grid item>
              <Swiper
                spaceBetween={16}
                slidesPerView="auto"
                pagination={{ clickable: true }}
                allowTouchMove={true}
                breakpoints={{
                  375: {
                    slidesPerView: 1,
                  },
                }}
                className={classes.swiperContainer}
                onSlideChange={(swiper) => {
                  const slide = [
                    "balance of shop",
                    "healthy insights",
                    "know your food groups",
                  ];
                  track(HOMEPAGE_EVENT.THREE_CAROUSEL_SCROLL, {
                    scrollTo: slide[swiper.activeIndex],
                  });
                }}
              >
                <SwiperSlide>
                  <BalanceOfShopV2 />
                </SwiperSlide>
                <SwiperSlide>
                  <HealthyInsights />
                </SwiperSlide>
                <SwiperSlide>
                  <FoodGroupInsightsSection />
                </SwiperSlide>
              </Swiper>
            </Grid>

            <Grid item>
              <GoalsSummary />
            </Grid>

            <Grid item>
              {isMobile && <BreakSeparator styles={{ height: "1px" }} />}
              <Typography className={classes.sectionHeaderText}>
                Healthy highlights
              </Typography>
            </Grid>
            <Grid item>
              <NutrientTrackerSummary />
            </Grid>
            <Grid item>
              <HealthierOptions forHomepage={true} source="homepage" />
            </Grid>
            {!hidePrograms && (
              <Grid item>
                <WaysToWellSection />
              </Grid>
            )}
            <BreakSeparator styles={{ height: "1px" }} />
            <Grid item>
              <HealthyInspiration source="home" articles={[]} />
            </Grid>
          </Grid>
        </Grid>
      )}

      {/*Desktop*/}
      {!isMobile && (
        <Grid container spacing={3} style={{ paddingBottom: "40px" }}>
          <Grid item sm={6}>
            <Grid item>
              <BalanceOfShopV2 />
            </Grid>
          </Grid>
          <Grid item sm={6}>
            <Grid item>
              <FoodGroupInsightsSection />
            </Grid>
            {!hidePrograms && (
              <Grid item>
                <WaysToWellSection />
              </Grid>
            )}
          </Grid>
          <Grid item sm={6}>
            <GoalsSummary />
          </Grid>
          <Grid item sm={6}>
            <HealthyInsights />
          </Grid>
          <Grid item sm={12}>
            <BreakSeparator
              marginTop={40}
              marginBottom={40}
              styles={{ height: "1px" }}
            />
          </Grid>
          <Grid item sm={12} container alignItems="stretch">
            <Typography
              variant="h1"
              component="h1"
              className={classes.sectionHeaderText}
            >
              Healthy highlights
            </Typography>
          </Grid>
          <Grid item sm={12} lg={4} container alignItems="stretch">
            <MainVsSometimesSection />
          </Grid>
          <Grid item sm={12} lg={4} container alignItems="stretch">
            <HealthierOptions forHomepage={true} source="homepage" />
          </Grid>
          <Grid item sm={12} lg={4} container alignItems="stretch">
            <NutrientTrackerSummary />
          </Grid>
          <Grid item sm={12}>
            <BreakSeparator
              marginTop={40}
              marginBottom={40}
              styles={{ height: "1px" }}
            />
          </Grid>
          <Grid item sm={12} container alignItems="stretch">
            <Typography
              variant="h1"
              component="h1"
              className={classes.sectionHeaderText}
            >
              Healthy inspiration
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <HealthyInspiration
              source="home"
              articles={[]}
              showHeader={false}
            />
          </Grid>
        </Grid>
      )}

      {member?.consented ? (
        isNonProdEnv() && <UpdatedTermsDialog />
      ) : (
        <ConsentDialog disabled={Boolean(error)} />
      )}
    </PageLayout>
  );
};

export default MainPage;
